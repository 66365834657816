@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins";
}

.bg-texture-1 {
  background-color: #e88515;
  opacity: 1;
  background-image: radial-gradient(#eca64f 1.1500000000000001px, transparent 1.1500000000000001px),
    radial-gradient(#eca64f 1.1500000000000001px, #e88515 1.1500000000000001px);
  background-size: 46px 46px;
  background-position: 0 0, 23px 23px;
}
